import React from "react";
import { Box, Typography } from "@mui/material";
import Card from "../components/ui/Card";
const NotFound = () => {
  return (
    <Card>
      <Box className="centered">
        <Typography variant="p" component="p">
          Page not found!
        </Typography>
      </Box>
    </Card>
  );
};
export default NotFound;
