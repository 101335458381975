import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { getPresentationTemplates } from "src/store/actions/templates";
import Content from "src/layouts/Content";
import CardItem from "src/components/common/CardItem";

const Emails = () => {
  const dispatch = useDispatch();
  const { compId, catId, type } = useParams();
  const { presentations: templates } = useSelector(
    (state) => state.templates.templates
  );

  useEffect(() => {
    dispatch(
      getPresentationTemplates({ id: compId, type, categoryId: catId })
    );
  }, [type]);

  return (
    <Content title={`${type?.charAt(0).toUpperCase()}${type?.slice(
      1
    )} Templates`}>
      <Grid container spacing={3}>
        {templates.length &&
          templates.map((template) => {
            return (
              <Grid key={template._id} item xs={12} md={3}>
                <CardItem image={template.thumbnail} title={template.name} />
              </Grid>
            );
          })}
      </Grid>
    </Content>
  );
};

export default Emails;
