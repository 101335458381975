import React from "react";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import { Box } from "@mui/material";

const Button = ({ loading, disabled, children, ...otherProps }) => {
  return (
    <MuiButton disabled={loading || disabled} {...otherProps}>
      {loading ? (
        <Box sx={{ pr: 0.8 }}>
          <CircularProgress
            thickness={7}
            size={15}
            sx={{ color: "white", display: "flex", alignItems: "center" }}
          />
        </Box>
      ) : null}
      {children}
    </MuiButton>
  );
};

export default Button;
