import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, TextField, Toolbar, Typography, Link } from "@mui/material";
import { uiActions } from "src/store/reducers/ui-slice";
import Main from "./Main";
import upload from "../../assets/images/upload.png";
import "../../styles/upload.scss";
const UploadFrom = (props) => {
  const {
    show,
    title,
    acceptFileType,
    subtitle,
    dispatchFunction,
    selectedCompany,
    close,
    externalElement,
    externalFields,
  } = props;
  const [file, setFile] = useState("");
  const nameRef = useRef();
  const sendFormData = (file, name) => {
    const data = new FormData();
    data.append("file", file);
    data.append("name", name);
    if (selectedCompany) {
      if (props.type) {
        data.append("type", props.type);
        if (externalFields) {
          Object.keys(externalFields).forEach((field) => {
            data.append(field, externalFields[field]);
          });
        }
      }
      dispatch(dispatchFunction({ data, id: selectedCompany }));
    }
    close();
  };

  const fileChecker = (file, name) => {
    if (
      file.type == "application/x-zip-compressed" ||
      file.type == "application/zip"
    ) {
      sendFormData(file, name);
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Wrong file!",
          message: "Please select a zip file",
        })
      );
    }
  };

  const dispatch = useDispatch();
  const uploadVersion = useRef();

  const closeDialog = () => {
    close();
  };

  const browseFileHandler = () => {
    uploadVersion.current.click();
  };

  const uploadImage = (e) => {
    setFile(e.target.files[0]);
  };

  // Drag & Drop Functionality
  const uploadOnDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const DragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };
  const DragLeaveHandler = (e) => {
    e.currentTarget.classList.remove("dragOver");
  };
  const submitHandler = () => {
    const name = nameRef.current.value;
    if (name && typeof file === "object") {
      fileChecker(file, name);
    }
  };
  return (
    <Main className="uploadPresentaion" isOpen={show} handleClose={closeDialog}>
      <Box className="dialogBody">
        <Typography variant="h3" className="uploadHead">
          {title}
        </Typography>
        <div className="divider"></div>
        <TextField
          inputRef={nameRef}
          sx={{ marginBottom: externalElement ? 4 : 1 }}
          fullWidth
          placeholder="Enter Name"
        />
        {externalElement && externalElement}
       
        <Typography variant="h2">
          Selected File:
          {file && (
            <Typography component="span" variant="h3">
              {file.name}
            </Typography>
          )}
        </Typography>
        <div
          className="uploadArea"
          onClick={browseFileHandler}
          onDragOver={DragOverHandler}
          onDragLeave={DragLeaveHandler}
          onDrop={uploadOnDrop}
        >
          <input
            type="file"
            name="uploadAsset"
            id="uploadAsset"
            hidden
            accept={acceptFileType}
            onChange={uploadImage}
            ref={uploadVersion}
          />

          <img src={upload} className="uploadImage" alt="upload"  />
          <Typography textAlign="center" className="heading">
            Drag &amp; Drop
          </Typography>
          <Typography textAlign="center" className="subHeading">
            {subtitle}
          </Typography>
        </div>
        <br/>
        

        <Box display="flex" justifyContent="center" alignItems="center" sx={{gap:'10px'}}>
          <Link rel="noopener" target="_blank" href="https://bssuniversal.atlassian.net/wiki/spaces/CREATESMAR/pages/21233665/Download+Upload+Source+Code+File+Structure#Presentation">Upload Guide</Link>
          <Button onClick={submitHandler} variant="contained" disabled={!file}>
          Submit
        </Button>
          </Box>
      </Box>
    </Main>
  );
};

export default UploadFrom;

UploadFrom.defaultProps = {
  subtitle: "Select a Zip File from your computer",
  acceptFileType: ".zip",
};
