export const languages = [
  {
    name: "Afar",
  },
  {
    name: "Abkhazian",
  },
  {
    name: "Afrikaans",
  },
  {
    name: "Akan",
  },
  {
    name: "Amharic",
  },
  {
    name: "Aragonese",
  },
  {
    name: "Arabic",
  },
  {
    name: "Assamese",
  },
  {
    name: "Avar",
  },
  {
    name: "Aymara",
  },
  {
    name: "Azerbaijani",
  },
  {
    name: "Bashkir",
  },
  {
    name: "Belarusian",
  },
  {
    name: "Bulgarian",
  },
  {
    name: "Bihari",
  },
  {
    name: "Bislama",
  },
  {
    name: "Bambara",
  },
  {
    name: "Bengali",
  },
  {
    name: "Tibetan",
  },
  {
    name: "Breton",
  },
  {
    name: "Bosnian",
  },
  {
    name: "Catalan",
  },
  {
    name: "Chechen",
  },
  {
    name: "Chamorro",
  },
  {
    name: "Corsican",
  },
  {
    name: "Cree",
  },
  {
    name: "Czech",
  },
  {
    name: "Old Church Slavonic / Old Bulgarian",
  },
  {
    name: "Chuvash",
  },
  {
    name: "Welsh",
  },
  {
    name: "Danish",
  },
  {
    name: "German",
  },
  {
    name: "Divehi",
  },
  {
    name: "Dzongkha",
  },
  {
    name: "Ewe",
  },
  {
    name: "Greek",
  },
  {
    name: "English",
  },
  {
    name: "Esperanto",
  },
  {
    name: "Spanish",
  },
  {
    name: "Estonian",
  },
  {
    name: "Basque",
  },
  {
    name: "Persian",
  },
  {
    name: "Peul",
  },
  {
    name: "Finnish",
  },
  {
    name: "Fijian",
  },
  {
    name: "Faroese",
  },
  {
    name: "French",
  },
  {
    name: "West Frisian",
  },
  {
    name: "Irish",
  },
  {
    name: "Scottish Gaelic",
  },
  {
    name: "Galician",
  },
  {
    name: "Guarani",
  },
  {
    name: "Gujarati",
  },
  {
    name: "Manx",
  },
  {
    name: "Hausa",
  },
  {
    name: "Hebrew",
  },
  {
    name: "Hindi",
  },
  {
    name: "Hiri Motu",
  },
  {
    name: "Croatian",
  },
  {
    name: "Haitian",
  },
  {
    name: "Hungarian",
  },
  {
    name: "Armenian",
  },
  {
    name: "Herero",
  },
  {
    name: "Interlingua",
  },
  {
    name: "Indonesian",
  },
  {
    name: "Interlingue",
  },
  {
    name: "Igbo",
  },
  {
    name: "Sichuan Yi",
  },
  {
    name: "Inupiak",
  },
  {
    name: "Ido",
  },
  {
    name: "Icelandic",
  },
  {
    name: "Italian",
  },
  {
    name: "Inuktitut",
  },
  {
    name: "Japanese",
  },
  {
    name: "Javanese",
  },
  {
    name: "Georgian",
  },
  {
    name: "Kongo",
  },
  {
    name: "Kikuyu",
  },
  {
    name: "Kuanyama",
  },
  {
    name: "Kazakh",
  },
  {
    name: "Greenlandic",
  },
  {
    name: "Cambodian",
  },
  {
    name: "Kannada",
  },
  {
    name: "Korean",
  },
  {
    name: "Kanuri",
  },
  {
    name: "Kashmiri",
  },
  {
    name: "Kurdish",
  },
  {
    name: "Komi",
  },
  {
    name: "Cornish",
  },
  {
    name: "Kyrgyz",
  },
  {
    name: "Latin",
  },
  {
    name: "Luxembourgish",
  },
  {
    name: "Ganda",
  },
  {
    name: "Limburgian",
  },
  {
    name: "Lingala",
  },
  {
    name: "Laotian",
  },
  {
    name: "Lithuanian",
  },
  {
    name: "Luba-Katanga",
  },
  {
    name: "Latvian",
  },
  {
    name: "Malagasy",
  },
  {
    name: "Marshallese",
  },
  {
    name: "Maori",
  },
  {
    name: "Macedonian",
  },
  {
    name: "Malayalam",
  },
  {
    name: "Mongolian",
  },
  {
    name: "Moldovan",
  },
  {
    name: "Marathi",
  },
  {
    name: "Malay",
  },
  {
    name: "Maltese",
  },
  {
    name: "Burmese",
  },
  {
    name: "Nauruan",
  },
  {
    name: "Norwegian Bokmål",
  },
  {
    name: "North Ndebele",
  },
  {
    name: "Nepali",
  },
  {
    name: "Ndonga",
  },
  {
    name: "Dutch",
  },
  {
    name: "Norwegian Nynorsk",
  },
  {
    name: "Norwegian",
  },
  {
    name: "South Ndebele",
  },
  {
    name: "Navajo",
  },
  {
    name: "Chichewa",
  },
  {
    name: "Occitan",
  },
  {
    name: "Ojibwa",
  },
  {
    name: "Oromo",
  },
  {
    name: "Oriya",
  },
  {
    name: "Ossetian / Ossetic",
  },
  {
    name: "Panjabi / Punjabi",
  },
  {
    name: "Pali",
  },
  {
    name: "Polish",
  },
  {
    name: "Pashto",
  },
  {
    name: "Portuguese",
  },
  {
    name: "Quechua",
  },
  {
    name: "Raeto Romance",
  },
  {
    name: "Kirundi",
  },
  {
    name: "Romanian",
  },
  {
    name: "Russian",
  },
  {
    name: "Rwandi",
  },
  {
    name: "Sanskrit",
  },
  {
    name: "Sardinian",
  },
  {
    name: "Sindhi",
  },
  {
    name: "Northern Sami",
  },
  {
    name: "Sango",
  },
  {
    name: "Serbo-Croatian",
  },
  {
    name: "Sinhalese",
  },
  {
    name: "Slovak",
  },
  {
    name: "Slovenian",
  },
  {
    name: "Samoan",
  },
  {
    name: "Shona",
  },
  {
    name: "Somalia",
  },
  {
    name: "Albanian",
  },
  {
    name: "Serbian",
  },
  {
    name: "Swati",
  },
  {
    name: "Southern Sotho",
  },
  {
    name: "Sundanese",
  },
  {
    name: "Swedish",
  },
  {
    name: "Swahili",
  },
  {
    name: "Tamil",
  },
  {
    name: "Telugu",
  },
  {
    name: "Tajik",
  },
  {
    name: "Thai",
  },
  {
    name: "Tigrinya",
  },
  {
    name: "Turkmen",
  },
  {
    name: "Tagalog / Filipino",
  },
  {
    name: "Tswana",
  },
  {
    name: "Tonga",
  },
  {
    name: "Turkish",
  },
  {
    name: "Tsonga",
  },
  {
    name: "Tatar",
  },
  {
    name: "Twi",
  },
  {
    name: "Tahitian",
  },
  {
    name: "Uyghur",
  },
  {
    name: "Ukrainian",
  },
  {
    name: "Urdu",
  },
  {
    name: "Uzbek",
  },
  {
    name: "Venda",
  },
  {
    name: "Vietnamese",
  },
  {
    name: "Volapük",
  },
  {
    name: "Walloon",
  },
  {
    name: "Wolof",
  },
  {
    name: "Xhosa",
  },
  {
    name: "Yiddish",
  },
  {
    name: "Yoruba",
  },
  {
    name: "Zhuang",
  },
  {
    name: "Chinese",
  },
  {
    name: "Zulu",
  },
];
