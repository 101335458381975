const deployedURL = "https://api.createsmart.io/api/v1.0";
// const localURL = "http://192.168.2.40:3002/api/v1.0";
// export const localURL = "http://localhost:3001/api/v1.0"; 
const localURL = "https://api.createsmart.io/api/v1.0";
export const apiURL = process.env.REACT_APP_API_BASE_URL || 'http://172.16.2.27:4000/api/v1.0'
// const urls = {
//   development: localURL,
//   production: deployedURL,
// };

// const apiURL = urls[process.env.NODE_ENV] || deployedURL;

export const api = {
  login: () => {
    return {
      url: `${apiURL}/admins/login`,
      method: "post",
    };
  },
  logout: () => {
    return {
      url: `${apiURL}/admins/logout`,
      method: "post",
    };
  },
  forgotPassword: () => {
    return {
      url: `${apiURL}/users/forgot`,
      method: "post",
    };
  },
  createCompany: () => {
    return {
      url: `${apiURL}/companies`,
      method: "post",
    };
  },
  getCompanies: () => {
    return {
      url: `${apiURL}/companies`,
      method: "get",
    };
  },
  getCompanyData: (id) => {
    return {
      url: `${apiURL}/companies/${id}`,
      method: "get",
    };
  },

  updateCompany: (id) => {
    return {
      url: `${apiURL}/companies/${id}`,
      method: "put",
    };
  },
  createPackage: () => {
    return {
      url: `${apiURL}/packages`,
      method: "post",
    };
  },
  getPackages: () => {
    return {
      url: `${apiURL}/packages`,
      method: "get",
    };
  },
  getPackage: (id) => {
    return {
      url: `${apiURL}/packages/${id}`,
      method: "get",
    };
  },
  updatePackage: (id) => {
    return {
      url: `${apiURL}/packages/${id}`,
      method: "put",
    };
  },
  getUsersList: (companyId) => {
    return {
      url: `${apiURL}/users/${companyId}`,
      method: "get",
    };
  },
  getAllUsers: () => {
    return {
      url: `${apiURL}/users`,
      method: "get",
    };
  },

  createUser: () => {
    return {
      url: `${apiURL}/users`,
      method: "post",
    };
  },

  getUser: (companyId, id) => {
    return {
      url: `${apiURL}/users/${companyId}/${id}`,
      method: "get",
    };
  },

  updateUser: (id) => {
    return {
      url: `${apiURL}/users/${id}`, //61dfeb2da3bdf9450cedd120
      method: "put",
    };
  },
  deleteUser: (id) => {
    return {
      url: `${apiURL}/users/${id}`, //61dfeb2da3bdf9450cedd120
      method: "delete",
    };
  },
  userVerify: (id) => {
    return {
      url: `${apiURL}/admins/userVerify/${id}`,
      method: "get",
    };
  },

  createTeam: () => {
    return {
      url: `${apiURL}/admins`,
      method: "post",
    };
  },


  getTeam: (id) => {
    return {
      url: `${apiURL}/admins/${id}`,
      method: "get",
    };
  },


  updateAdmin: (id) => {
    return {
      url: `${apiURL}/admins/${id}`, //61dfeb2da3bdf9450cedd120
      method: "put",
    };
  },

  getTeamsList: (id) => {
    return {
      url: `${apiURL}/admins`, //61dfeb2da3bdf9450cedd120
      method: "get",
    };
  },
  getCountries: () => {
    return {
      url: `https://api.countrystatecity.in/v1/countries`,
      method: "get",
    };
  },
  getStates: (data) => {
    return {
      url: `https://api.countrystatecity.in/v1/countries/${data.country_code}/states`,
      method: "get",
    };
  },
  getCities: (data) => {
    return {
      url: `https://api.countrystatecity.in/v1/countries/${data.country_code}/states/${data.state_code}/cities`,
      method: "get",
    };
  },
  getPresentationTemplates: (id, type, categoryId) => {
    let url = `${apiURL}/templates-admin/${id}?type=${type}`;
    if (categoryId) {
      url = `${apiURL}/templates-admin/${id}/${categoryId}?type=${type}`;
    }
    return {
      url,
      method: "get",
    };
  },
  uploadPresentationTemplate: (id) => {
    return {
      url: `${apiURL}/templates/${id}`,
      method: "post",
    };
  },
  getSlideTemplates: (id) => {
    return {
      url: `${apiURL}/templates/slides/${id}`,
      method: "get",
    };
  },
  uploadSlideTemplate: (id) => {
    return {
      url: `${apiURL}/templates/slides/${id}`,
      method: "post",
    };
  },
  fetchTemplatesCategories: (id, type) => {
    return {
      url: `${apiURL}/template-category/${id}?type=${type}`,
      method: "get",
    };
  },
  createTemplateCaterogy: (id) => {
    return {
      url: `${apiURL}/template-category/${id}`,
      method: "post",
    };
  },
  updateTemplate: (templateId, companyId) => {
    return {
      url: `${apiURL}/templates/${templateId}/company/${companyId}`,
      method: "patch",
    };
  },
};