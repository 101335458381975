import React, {useEffect} from 'react'
import {
    Box,
    Card,
    Typography,
    Grid
  } from "@mui/material";
import SelectFields from "src/components/ui/formElements/SelectFields";
import InputFields from "src/components/ui/formElements/InputFields";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/styles.scss";
import {
    getCities,
    getCountries,
    getStates,
  } from "src/store/actions/countries";

const AddressView = (props) =>{

    const { countries, states, cities } = useSelector(
        (state) => state.country.fetching
      );
    const dispatch = useDispatch();
    const {
        control,
        errors ,
        watch,
        resetField
    }=props

    const country = watch("address.country");
    const state = watch("address.state");

    useEffect(() => {
        dispatch(getCountries());
      }, []);
      
      useEffect(async () => {
        if (country) {
            resetField("address.state");
            resetField("address.city");
            dispatch(getStates({ country_code: country }));
        }
      }, [country]);
    
      useEffect(async () => {
        // Default options are marked with *
        console.log(state)
        if (state) {
            resetField("address.city");
            dispatch(getCities({ country_code: country, state_code: state }));
        }
      }, [state]);

    return(
        <Card className="form-card">
          <Box className="form-heading">
            <Typography variant="h1">Address:</Typography>
          </Box>
          <Grid container columns={{ xs: 10 }} spacing={2}>
            <Grid item xs={2}>
               <SelectFields
                    fieldName="address.country"
                    label="Country"
                    disabled={countries.length === 0 ? true : false}
                    control={control}
                    options={countries.map((country) => {
                      return { label: country.name, value: country.iso2 };
                    })}
                    withSearch
                  />
            </Grid>
            <Grid item xs={2}>
              <SelectFields
                fieldName="address.state"
                label="State"
                disabled={states.length === 0 ? true : false}
                control={control}
                options={
                    states.map((state) => {
                          return {
                            label: state.name,
                            value: state.iso2,
                          };
                    })
                }
                withSearch
              />
            </Grid>
            <Grid item xs={2}>
              <SelectFields
                fieldName="address.city"
                disabled={cities.length === 0 ? true : false}
                label="City"
                control={control}
                options={ 
                  cities.map((city) => {
                    return { label: city.name, value: city.name };
                  })
                }
                withSearch
              />
            </Grid>

            <Grid item xs={2}>
              <InputFields
                type="text"
                fieldName="address.street"
                label="Street"
                control={control}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFields
                type="text"
                fieldName="address.postalCode"
                label="Postal Code"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/gi,
                    message: "Postal Code Should Not Contain Alphabets",
                  },
                }}
                error={errors.address?.postalCode}
              />
            </Grid>
          </Grid>
        </Card>
    )
}

export default AddressView