import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiPencilFill } from "react-icons/ri";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { getPackages } from "src/store/actions/packages";
import Tables from "src/components/common/Tables";
import { headCellsPackages } from "src/components/common/tablesData";
import Content from "src/layouts/Content";

const PackagesAction = ({ id }) => {
  return (
    <>
      <Box>
        <Tooltip title="Edit">
          <Link to={`/packages/edit/${id}`}>
            <IconButton>
              <RiPencilFill size={18} />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    </>
  );
};

const Permissions = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const packages = useSelector((state) => state.packages.list);

  useEffect(async () => {
    await dispatch(getPackages());
    setIsLoading(false);
  }, []);

  return (
    <Content
      title="Packages"
      action={
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link to="create">
              <Button variant="contained">Create Package</Button>
            </Link>
          </Grid>
        </Grid>
      }
    >
      {isLoading ? (
        "Loading..."
      ) : (
        <Tables
          Action={PackagesAction}
          rows={packages}
          headCells={headCellsPackages}
        />
      )}
    </Content>
  );
};

export default Permissions;
