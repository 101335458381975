import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

import { uiActions } from "../reducers/ui-slice";

export const login = createAsyncThunk(
  "login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await HttpService.call(api.login(), { email, password },thunkAPI);
      if (response.data.jwt) {
        // Storing data in cookie

        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Welcome to CreateSmart",
          })
        );
      }
      return { user: response.data };
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Email/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("logout", async (thunkAPI) => {
  try {
    const response = await HttpService.call(api.logout());
    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    thunkAPI.dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Something Wrong",
        message: "Please try again",
      })
    );
    return thunkAPI.rejectWithValue();
  }
});

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await HttpService.call(api.forgotPassword(), { email });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateAdmin = createAsyncThunk(
  "updateAdmin",
  async ({ _id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updateAdmin(_id),
        data,
        thunkAPI,
        data.isDeleted?"Your Record Has Been Deleted":"Your Record Has Been Updated"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userVerify = createAsyncThunk(
  "userVerify",
  async ({id}, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.userVerify(id),
        undefined,
        thunkAPI
      );

      return { user: response.data };
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
