import { createSlice } from "@reduxjs/toolkit";
import { getUsersList, getUser, getAllUsers, updateUser } from "../actions/user";

const initialState = {
  fetching: {
    user: {},
    list: [],
  },
  company: "",
};
const usersSlice = createSlice({
  name: "user slice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.fetching.user = {};
      state.fetching.list = [];
    },
    deleteUser: (state, action) => {
      console.log(action.payload)
      state.fetching.list = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
  },
  extraReducers: {
    [getUsersList.fulfilled]: (state, action) => {
      state.fetching.list = action.payload;
    },
    [getUser.fulfilled]: (state, action) => {
      state.fetching.user = action.payload;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.fetching.list = action.payload;
    },
    [updateUser.fulfilled]: (state, action) => {
      const index = state.fetching.list.findIndex(item => item._id == action.payload._id)
      state.fetching.list[index]=action.payload
    },
  },
});

export const { clearState, deleteUser, setCompany } = usersSlice.actions;
export default usersSlice.reducer;
