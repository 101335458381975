import { Typography, TextField } from "@mui/material";
const Input = (props) => {
  const {label, value, type, name, ref, placeholder, className, onChange, labelTag, inputProps } = props
  const onChangeHandler = (e) => {
    const inputVal = e.target.value
    if(inputProps && type === 'number' && (inputVal < inputProps?.min || inputVal > inputProps?.max)){
     return false
    }
    onChange(e)
  }
  return (
    <>
      {labelTag && (
        <Typography variant="body1" component="label" className="labelTag">
          {label}
        </Typography>
      )}
      <TextField
        // fullWidth
        // id="outlined-basic"
        label={label}
        type={type}
        name={name}
        placeholder={placeholder}
        className={className}
        onChange = {onChangeHandler}
        value = {value}
        inputRef = {ref}
      />
    </>
  );
};

export default Input;
