import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AiFillDelete, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { RiPencilFill } from "react-icons/ri";
import {
  deleteUser,
  getAllUsers,
  getUsersList,
} from "../../store/actions/user";
import { getCompanies } from "../../store/actions/company";
import {
  clearState,
  deleteUser as deleteFromRedux,
} from "src/store/reducers/user-slice";
import { Button, IconButton, Box, Grid, Tooltip } from "@mui/material";
import SelectBox from "src/components/ui/formElements/SelectBox";
import Tables from "../../components/common/Tables";
import Custom from "src/components/dialogs/Custom";
import { headCellsUser } from "src/components/common/tablesData";
import Content from "src/layouts/Content";

const userActions = ({ id, companyId }) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.users.fetching);
  const { notification } = useSelector((state) => state.ui);
  const [isOpen, setIsOpen] = useState(false);
  const [doDelete, setDoDelete] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (notification?.status === "success" && doDelete) {
      const newList = list.filter((val) => val._id !== id);
      dispatch(deleteFromRedux(newList));
    }
  }, [notification, doDelete]);

  const delteUserHandler = () => {
    setDoDelete(true);
    dispatch(deleteUser(id));
  };
  const buttonStyle = {
    backgroundColor: '#d80000', // You can change this color to your preference
    color: 'white', // Text color
  };
  return (
    <>
      <Custom 
        handleClose={handleClose}
        action={
          <div className="delete-button"  >
            <Button
              onClick={delteUserHandler}
              variant="contained"
              endIcon={<AiOutlineCheck />}
              style={buttonStyle}
            >
              Yes
            </Button>
            <Button
            sx={{ mr: 2 }}
              startIcon={<AiOutlineClose />}
              onClick={() => setIsOpen(false)}
              variant="outlined"
            >
              No
            </Button>
          </div>
        }
        isOpen={isOpen}
        title="Do you want to delete?"
      />
      <Box>
        <Link
          onClick={() => dispatch(clearState())}
          to={`/user/${companyId}/edit/${id}`}
        >
          <Tooltip title="Edit">
          <IconButton>
            <RiPencilFill size={18} />
          </IconButton>
          </Tooltip>
        </Link>
        <Tooltip title="Delete">
        <IconButton onClick={() => setIsOpen(true)}>
          <AiFillDelete size={18} />
        </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

const UsersList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedCompany } = useParams();
  const { companyName } = location?.state;
  const { list: companyList } = useSelector((state) => state.company.fetching);
  const { list } = useSelector((state) => state.users.fetching);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getUsersList(selectedCompany));
    } else {
      dispatch(getAllUsers());
    }
  }, [selectedCompany]);

  const handleCompanyChange = (e) => {
    dispatch(clearState());
    if (e.target.value !== "all") navigate(`/user/${e.target.value}`);
    else navigate("/user");
  };
  return (
    <Content
      title={`${companyName} - Users`}
      action={
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button LinkComponent={Link} to="create" variant="contained">
              Create User
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Tables rows={list} headCells={headCellsUser} Action={userActions} />
    </Content>
  );
};

export default UsersList;
