import React, { Navigate } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import BlankLayout from "./layouts/BlankLayout";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import PageContent from "./layouts/PageContent";

// Dashboard Page
import Dashboard from "./pages/Dashboard/Dashboard";
// User Page
import UsersList from "./pages/User/UsersList";
import UserFormPage from "./pages/User/UserFormPage";

// Company Page
import ComapnyCreate from "./pages/Company/ComapnyCreate";
import CompanyList from "./pages/Company";

// Team Page
import TeamList from "./pages/Team/TeamList";
import TeamForm from "./pages/Team/TeamForm";
import ForgotPassword from "./pages/ForgotPassword";
import Presentations from "./pages/Templates/Presentations";
import Slides from "./pages/Templates/Slides";
import Emails from "./pages/Templates/Emails";
import LandingPage from "./pages/Templates/LandingPage";
import { BiSlideshow } from "react-icons/bi";
import { HiPresentationChartLine } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import PresentationTemplates from "./pages/TemplatesCategory/Presentation";
import EmailTemplates from "./pages/TemplatesCategory/Email";
import EmailCategoryTemplates from "./pages/Templates/EmailTemplates";
import Packages from "./pages/packages/Packages";
import PackagesCreate from "./pages/packages/PackagesCreate";
import MyProfile from "./pages/Profile/MyProfile";

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" replace/>,
    children: [
      {
        path: "dashboard",
        element: <PageContent />,
        children: [{ path: "data", element: <Dashboard /> }],
      },
      {
        path: "team",
        element: <PageContent />,
        children: [
          { path: "/team", element: <TeamList /> },
          { path: "create", element: <TeamForm /> },
          { path: "edit/:id", element: <TeamForm /> },
        ],
      },
      {
        path: "profile",
        element: <PageContent />,
        children: [
          {
            path: "my-account",
            element: <MyProfile />,
          },
        ],
      },
      {
        path: "user",
        element: <PageContent />,
        children: [
          { path: "/user", element: <UsersList /> },
          { path: "/user/:selectedCompany", element: <UsersList /> },
          { path: "create", element: <UserFormPage /> },
          { path: ":selectedCompany/create", element: <UserFormPage /> },
          { path: ":selectedCompany/edit/:id", element: <UserFormPage /> },
        ],
      },
      {
        path: "company",
        element: <PageContent />,
        children: [
          { path: "/company", element: <CompanyList /> },
          { path: "create", element: <ComapnyCreate /> },
          { path: ":id", element: <ComapnyCreate /> },
        ],
      },
      {
        path: "packages",
        element: <PageContent />,
        children: [
          { path: "/packages", element: <Packages /> },
          { path: "create", element: <PackagesCreate /> },
          { path: "edit/:id", element: <PackagesCreate /> },
        ],
      },
      {
        path: "templates",
        element: (
          <PageContent
            tabs={[
              {
                label: "Presentations",
                path: "presentations",
                icon: <HiPresentationChartLine />,
              },
              {
                label: "Slides",
                path: "slides",
                icon: <BiSlideshow />,
              },
              {
                label: "Emails",
                path: "emails",
                icon: <MdEmail />,
              },
              {
                label: "Landing Pages",
                path: "landingpages",
                icon: <MdEmail />,
              },
            ]}
          />
        ),
        children: [
          { path: "presentations", element: <Presentations /> },
          { path: "slides", element: <Slides /> },
          { path: "emails", element: <Emails /> },
          { path: "landingpages", element: <LandingPage /> },
        ],
      },
      {
        path: "templates-category",

        element: (
          <PageContent
            tabs={[
              {
                label: "Presentations",
                path: "presentation",
                icon: <HiPresentationChartLine />,
              },
              {
                label: "Emails",
                path: "email",
                icon: <MdEmail />,
              },
            ]}
          />
        ),
        children: [
          { index: true, element: <Navigate to="presentation" replace/> },
          // { path: "presentations", element: <PresentationTemplates /> },
          {
            path: ":type", element: <EmailTemplates />,
          },
        ],
      },
      {
        path: "templates-category/:type",
        element: <PageContent />,
        children: [
          {
            path: ":compId/:catId",
            element: <EmailCategoryTemplates />,
          }
        ],
      },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? <BlankLayout /> : <Navigate to="dashboard/data" replace/>,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/forgotpassword", element: <ForgotPassword /> },
    ],
  },
];

export default routes;
