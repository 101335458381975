import React from "react";
import TemplateCategory from "./TemplateCategory";
import { useParams } from "react-router-dom";

const Email = () => {
  const { type } = useParams();
  return <TemplateCategory type={type} />;
};

export default Email;
