export const permissions = {
  name: "",
  permissions: {
    salesforceIntegration: false,
    storageCapacity: false,
    share: false,
    approvalProcess: false,
    presentation: {
      maxNumberOfSlides: 3,
      versions: false,
      commentsAndCollaboration: false,
      create: {
        uploadZipFile: false,
        createViaTemplate: false,
        clone: false,
        // uploadNewVersion: false,
      },
      export: {
        downloadSourceCode: false,
        downloadBuild: false,
        downloadAsPDF: false,
        downloadAsPPT: false,
        applicationLinkForIOS: false,
        applicationLinkForAndroid: false,
      },
      components: {
        image: false,
        text: false,
        card: false,
        container: false,
        popup: false,
        radio: false,
        checkbox: false,
        table: false,
        flipCard: false,
        button: false,
        slider: false,
        audio: false,
        video: false,
        input: false,
        graph: false,
        coverFlow: false,
        carousel: false,
        extendedCard: false,
        tabs: false,
        list: false,
      },
      chapter: {
        createNew: false,
        hide: false,
        lock: false
      },
    },
    emailTemplates: {
      versions: false,
      commentsAndCollaboration: false,
      create: {
        uploadZipFile: false,
        createViaTemplate: false,
        clone: false,
        // uploadNewVersion: false,
      },
      export: {
        downloadSourceCode: false,
      },
      components: {
        image: false,
        text: false,
        card: false,
        table: false,
        input: false,
        grid: false,
        rating: false,
        divider: false,
        social: false,
        link: false,
      },
    },
    microsites: {
      versions: false,
      commentsAndCollaboration: false,
      create: {
        uploadZipFile: false,
        createViaTemplate: false,
        clone: false,
        // uploadNewVersion: false,
      },
      export: {
        downloadSourceCode: false,
      },
      components: {
        image: false,
        text: false,
        card: false,
        socialLinks: false,
        button: false,
        radio: false,
        checkbox: false,
        table: false,
        flipCard: false,
        video: false,
        input: false,
        graph: false,
        coverFlow: false,
        carousel: false,
        extendedCard: false,
        tabs: false,
        list: false,
        ratting: false,
        divider: false,
      },
    },
    campaign: {
      analytics: false
    },
    userSettings: {
      maxNumberOfUsers: 0,
    },
  },
};
