import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

const CheckBox = ({ label, fieldName, control, rules }) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={<Checkbox />}
            label={label}
            ref={field.ref}
            checked={field.value}
            onChange={field.onChange}
          />
        );
      }}
    />
  );
};

export default CheckBox;
