import { useEffect } from "react";
import { uiActions } from "src/store/reducers/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";
import "../../styles/Notification.scss";

const Notification = (props) => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.ui);
  let specialClasses = "";

  if (props.status === "error") {
    specialClasses = "error";
  }
  if (props.status === "success") {
    specialClasses = "success";
  }

  const cssClasses = `${"notification"} ${specialClasses}`;
  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        dispatch(uiActions.hideNotification());
      }, 3000);
    }
  }, [notification]);

  return (
    <Alert sx={{ borderRadius: 0, position: 'fixed', right: '10px', top: 10, width: '20%',zIndex:15 }} severity={props.status}>
      <AlertTitle>{props.title}</AlertTitle>
      {props.message}
    </Alert>
  );
};

export default Notification;
