import { createSlice } from "@reduxjs/toolkit";
import { getTeamsList } from "../actions/team";

const initialState = {
  fetching: { list: [] },
};
const teamSlice = createSlice({
  name: "Team slice",
  initialState,
  extraReducers: {
    [getTeamsList.fulfilled]: (state, action) => {
      state.fetching.list = action.payload;
    },
  },
});

export default teamSlice.reducer;
