import { permissions } from "src/data/permissions";

const { createSlice } = require("@reduxjs/toolkit");
const { getPackages, getPackage } = require("../actions/packages");

const packagesSlice = createSlice({
  name: "packages",
  initialState: {
    list: [],
    permissionData: permissions,
  },
  extraReducers: (builder) => {
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(getPackage.fulfilled, (state, action) => {
      state.permissionData = action.payload;
    });
  },
});

export default packagesSlice.reducer;
