import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSlideTemplates, uploadSlideTemplate } from 'src/store/actions/templates'
import CardItem from 'src/components/common/CardItem'
import Content from 'src/layouts/Content'
import { Button, Grid, Typography } from '@mui/material'
import { IoAdd } from 'react-icons/io5'
import { getCompanies } from 'src/store/actions/company'
import UploadFrom from 'src/components/dialogs/UploadFrom'
import SelectBox from 'src/components/ui/formElements/SelectBox'
import { clearState } from 'src/store/reducers/template-slice'


const Slides = () => {
    const { isLoading } = useSelector((state) => state.ui);
    const dispatch = useDispatch()
    const { list } = useSelector((state) => state.company.fetching);
    const { slides } = useSelector((state) => state.templates.templates);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        dispatch(clearState())
        dispatch(getCompanies())
    }, [])
    const [selectedCompany, setSelectedCompany] = useState("")

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value)
        dispatch(getSlideTemplates(e.target.value))
    }

    return (
        <Content
            title="Slides"
            action={
                <div className='custom-select-box'>
                <Grid spacing={1} container justifyContent='flex-end'>
                    <Grid item md={8}>
                        <SelectBox
                            className="company-select"
                            label="Select Company"
                            onChange={handleCompanyChange}
                            options={list.length !== 0 ? list.map((val) => {
                                return { label: val.name, value: val._id }
                            }) : [{ label: '', value: '' }]}
                            value={selectedCompany}
                        />
                    </Grid>
                    <Grid item md>
                        <Button disabled={selectedCompany ? false : true} onClick={() => setIsOpen(true)} startIcon={<IoAdd />} variant="contained">Add New</Button>
                    </Grid>
                </Grid>
                </div>
            }
        >
            <UploadFrom
                show={isOpen}
                title="Upload From"
                dispatchFunction={uploadSlideTemplate}
                close={() => setIsOpen(false)}
                selectedCompany={selectedCompany}
            />
            <Grid container spacing={3}>
                {!selectedCompany ?
                    <Grid item xs={12}>
                        <Typography variant="h1">Please Select a Company</Typography>
                    </Grid>
                    :
                    slides.length !== 0 ?
                        slides.map((slide) => {
                            return (
                                <Grid key={slide._id} item xs={12} md={3}>
                                    <CardItem
                                        image={slide.thumbnail}
                                        title={slide.name}
                                    />
                                </Grid>
                            )
                        })
                        :
                        !isLoading &&
                        <Grid item xs={12}>
                            <Typography variant="h1">This Company Has No Templates</Typography>
                        </Grid>
                }


            </Grid>
        </Content>
    )
}

export default Slides
