import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { validEmailPattern, validPhone } from "src/utils/Utils";
import { createCompany, getCompanyData, updateCompany } from "src/store/actions/company";
import {
  getCities,
  getCountries,
  getStates,
} from "src/store/actions/countries";

import { languages } from "src/data/langs";
import { currencies } from "src/data/currency";
import { timezones } from "src/data/timezones";

import SelectFields from "src/components/ui/formElements/SelectFields";
import InputFields from "src/components/ui/formElements/InputFields";
import CheckBox from "src/components/ui/formElements/CheckBox";
import Content from "src/layouts/Content";
import { uiActions } from "src/store/reducers/ui-slice";
import { getPackages } from "src/store/actions/packages";

import "../../styles/styles.scss";
import AddressView from "src/layouts/address/Address";
import InputPhoneField from "src/components/ui/formElements/InputPhoneField";
const ComapnyCreate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, notification } = useSelector((state) => state.ui);
  const [doNavigate, setDoNavigate] = useState(false);
  const packages = useSelector((state) => state.packages.list);

  // Setting Default Values For Form
  const defaultValues = useForm({
    defaultValues: {
      name: "",
      phone: "",
      fax: "",
      currency: "",
      timezone: "",
      website: "",
      locale: "",
      assetReview: true,
      packageId: "",
      primaryContact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      billing: {
        type: "",
      },
      address: {
        street: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
      },
    },
  });

  // Initialization For React-Hook-Form

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  useEffect(() => {
    dispatch(getPackages());

    if(id){
      dispatch(getCompanyData(id)).then(res=>{
        reset(res.payload)
      });
    }
  }, []);


  useEffect(() => {
    console.log(notification, doNavigate)
    if (notification?.status === "success" && doNavigate) {
      navigate(-1);
    }
    clearErrors();
    if (notification?.message === "Email Already Exists") {
      setError(
        "email",
        { message: notification?.message },
        { shouldFocus: true }
      );
    }
  }, [notification]);

  const save =  (data) => {
    if(id){
      dispatch(updateCompany({ id, data }))
    }else{
       dispatch(createCompany(data))
    }
    setDoNavigate(true);
  };

  return (
    <Content title={`${!id?"Create": "Update"} Company`}>
      <form>
        <Card className="form-card">
          <Box className="form-heading">
            <Typography variant="h1">Info:</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFields
                fieldName="name"
                type="text"
                label="Company Name"
                control={control}
                rules={{
                  minLength: {
                    value: 2,
                    message: "Company name should have at least 2 characters",
                  },
                  maxLength: {
                    value: 50,
                    message: "Company name allowed 50 characters",
                  },
                  required: "Company name is required",
                }}
                error={errors?.name}
                // inputProps={{ minLength: 2, maxLength: 40 }}
              />
            </Grid>
            <Grid item xs={3}>              
              <InputPhoneField 
              fieldName="phone"
              type="text"
              min={0}
              label="Company Phone"
              control={control}
              rules={{
                minLength: {
                  value: 11,
                  message: "Company Phone should have at least 11 characters",
                },
                maxLength: {
                  value: 16,
                  message: "Phone limit 16 characters",
                },
                required: "Company phone is required",
              }}
              error={errors?.phone}
              phone
              />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="fax"
                type="text"
                min={0}
                label="Company Fax"
                control={control}
                rules={{
                  minLength: {
                    value: 6,
                    message: "Fax should have at least 6 characters",
                  },
                  maxLength: {
                    value: 20,
                    message: "Fax limit 20 characters",
                  },
                  required: "Fax is required",
                }}
                error={errors?.fax}
                phone
              />
            </Grid>
            <Grid item xs={3}>
            <InputFields
                    fieldName="website"
                    type="text"
                    label="Company Website"
                    control={control}
                    rules={{
                      pattern: {
                        value:
                          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                        message: "Incorrect URL",
                      }
                    }}
                    error={errors?.website}
                  />
            </Grid>
            <Grid item xs={3}>
              <SelectFields
                fieldName="lang"
                label="Language"
                control={control}
                options={languages.map((lang) => {
                  return { label: lang.name, value: lang.name };
                })}
                withSearch
              />
            </Grid>

            <Grid item xs={3}>
              <SelectFields
                fieldName="currency"
                label="Currency"
                control={control}
                options={currencies.map(({ label, value }) => {
                  return { label, value };
                })}
                withSearch
              />
            </Grid>
            <Grid item xs={3}>
              <SelectFields
                fieldName="timezone"
                label="Timezone"
                control={control}
                options={timezones.map(({ label, value }) => {
                  return { label, value };
                })}
                withSearch
              />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="locale"
                type="text"
                label="locale"
                control={control}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
            <SelectFields
                fieldName="packageId"
                label="Company Package*"
                control={control}
                rules={{
                  required: "Company Package is required",
                }}
                options={packages.map(({name,_id}) => {
                  return { label: name, value: _id };
                })}
                error={errors?.packageId}
                withSearch
              />
            </Grid>
            <Grid item xs={2}>
              <CheckBox
                control={control}
                fieldName="assetReview"
                label="Asset Review"
              />
            </Grid>
          </Grid>
        </Card>

        <Grid container spacing={2}>
          <Grid item xs={8.5}>
            <Card className="form-card">
              <Box className="form-heading">
                <Typography variant="h1">Primary Contact:</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputFields
                    fieldName="primaryContact.firstName"
                    type="text"
                    label="First Name"
                    control={control}
                    rules={{
                      minLength: {
                        value: 2,
                        message: "First Name should have at least 3 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "First Name allowed 50 characters",
                      },
                      required: "First Name is required",
                    }}
                    error={errors.primaryContact?.firstName}
                    // inputProps={{ minLength: 2, maxLength: 40 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputFields
                    fieldName="primaryContact.lastName"
                    type="text"
                    label="Last Name"
                    control={control}
                    rules={{
                      minLength: {
                        value: 2,
                        message: "Last Name should have at least 3 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Last Name allowed 50 characters",
                      },
                      required: "Last Name is required",
                    }}
                    error={errors.primaryContact?.lastName}
                    // inputProps={{ minLength: 2, maxLength: 40 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputFields
                    fieldName="primaryContact.email"
                    type="email"
                    label="Email"
                    control={control}
                    rules={{
                      pattern: {
                        value: validEmailPattern,
                        message: "Incorrect Email",
                      },
                      required: "Email is required",
                    }}
                    error={errors.primaryContact?.email}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputPhoneField
                    fieldName="primaryContact.phone"
                    type="text"
                    label="Phone"
                    control={control}
                    rules={{
                      minLength: {
                        value: 11,
                        message: "Phone should have at least 11 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "Phone limit 16 characters",
                      },
                      required: "Phone is required",
                    }}
                    error={errors.primaryContact?.phone}
                    phone
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={3.5}>
            <Card className="form-card">
              <Box className="form-heading">
                <Typography variant="h1">Billing:</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputFields
                    fieldName="billing.type"
                    type="text"
                    label="Type"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <AddressView control={control} errors={errors} watch={watch} resetField={resetField}/>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            onClick={handleSubmit(save)}
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

export default ComapnyCreate;
