import { createSlice } from "@reduxjs/toolkit";
import {
  createTemplateCategory,
  fetchTamplatesCategories,
  getPresentationTemplates,
  getSlideTemplates,
  uploadPresentationTemplate,
  uploadSlideTemplate,
} from "../actions/templates";

const initialState = {
  templates: {
    presentations: [],
    slides: [],
  },
  categories: { list: [], isEmpty: false },
};

const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    clearState: (state) => {
      state.templates.presentations = [];
      state.templates.slides = [];
    },
  },
  extraReducers: {
    [getPresentationTemplates.fulfilled]: (state, action) => {
      state.templates.presentations = action.payload;
    },
    [uploadPresentationTemplate.fulfilled]: (state, action) => {
      state.templates.presentations.push(action.payload);
    },
    [getSlideTemplates.fulfilled]: (state, action) => {
      state.templates.slides = action.payload;
    },
    [uploadSlideTemplate.fulfilled]: (state, action) => {
      state.templates.slides.push(action.payload);
    },
    [createTemplateCategory.fulfilled]: (state, action) => {
      state.categories.list.push(action.payload);
      state.categories.isEmpty = false;
    },
    [fetchTamplatesCategories.fulfilled]: (state, action) => {
      state.categories.list = action.payload;
      if (!action.payload.length) {
        state.categories.isEmpty = true;
      } else {
        state.categories.isEmpty = false;
      }
    },
  },
});

export const { clearState } = templateSlice.actions;
export default templateSlice.reducer;
