import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

export const getUsersList = createAsyncThunk(
  "getUsersList",
  async (companyId, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getUsersList(companyId),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getAllUsers(),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk(
  "createUser",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.createUser(),
        data,
        thunkAPI,
        "Your Record Has Been Saved"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk(
  "getUser",
  async ({ id, companyId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getUser(companyId, id),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updateUser(id),
        data,
        thunkAPI,
        "Your Record Has Been Updated"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.deleteUser(id),
        null,
        thunkAPI,
        "Your Record Has Been Deleted"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
