const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <div className="copy-right">
      <p>
        @{year}, Made by <a href="https://bssuniversal.com/">Business Solutions & Services</a>
      </p>
    </div>
  );
};

export default Copyright;
