import React from "react";
import Card from "src/components/ui/Card";
import PropTypes from "prop-types";
import { NavLink, Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import "../styles/PageContent.scss";

const PageContent = (props) => {
  const active = "active";
  return (
    <>
      {props.tabs && props.tabs.length > 1 && (
        <Box className="pageTabs">
          {props.tabs.map((tab, index) => {
            return (
              <NavLink
                key={index}
                to={tab.path}
                className={(navData) =>
                  `${tab.disabled ? "disabled" : ""} 
                  ${navData.isActive ? active : ""}`
                }
              >
                {tab.icon}&nbsp;
                <Typography variant="body1" component="span">
                  {tab.label}
                </Typography>
              </NavLink>
            );
          })}
        </Box>
      )}
      {props.wrapper ? (
        <Card classes="screen">
          <Outlet />
        </Card>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PageContent;

PageContent.defaultProps = {
  wrapper: true,
};
