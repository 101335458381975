import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPresentationTemplates, updateTemplate, uploadPresentationTemplate } from 'src/store/actions/templates'
import CardItem from 'src/components/common/CardItem'
import Content from 'src/layouts/Content'
import { Button, Grid, Typography} from '@mui/material'
import { IoAdd } from 'react-icons/io5'
import { getCompanies } from 'src/store/actions/company'
import UploadFrom from 'src/components/dialogs/UploadFrom'
import SelectBox from 'src/components/ui/formElements/SelectBox'
import { clearState } from 'src/store/reducers/template-slice'

const Presentations = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.ui);
    const { list } = useSelector((state) => state.company.fetching);
    const { presentations: presentationTemplates } = useSelector((state) => state.templates.templates);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(clearState())
        dispatch(getCompanies())
    }, [])
    const [selectedCompany, setSelectedCompany] = useState("")

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value)
        dispatch(getPresentationTemplates({ id: e.target.value, type: "presentation" }))
    }

    const onDelete = (item) =>{
        const companyUID = list.filter((res)=> res._id == selectedCompany)
        const data = { "isDeleted":true}
        dispatch(updateTemplate({templateId:item?._id, companyId:companyUID[0]?.UID,data})).then((res)=>{
            dispatch(getPresentationTemplates({ id:selectedCompany, type: "presentation" }))
        });
    }

    return (
        <Content
            title="Presentations"
            action={
                <div className='custom-select-box'>
                <Grid spacing={1} container justifyContent='flex-end'>
                    <Grid item md={8}>
                        <SelectBox
                            className="company-select"
                            label="Select Company"
                            onChange={handleCompanyChange}
                            options={list.length !== 0 ? list.map((val) => {
                                return { label: val.name, value: val._id }
                            }) : [{ label: '', value: '' }]}
                            value={selectedCompany}
                        />
                    </Grid>
                    <Grid item md>
                        <Button disabled={selectedCompany ? false : true} onClick={() => setIsOpen(true)} startIcon={<IoAdd />} variant="contained">Add New</Button>
                    </Grid>
                </Grid>
                </div>
            }
        >
            <UploadFrom
                selectedCompany={selectedCompany}
                dispatchFunction={uploadPresentationTemplate}
                type="presentation"
                show={isOpen}
                title="Upload From"
                close={() => setIsOpen(false)}
            />
            <Grid container spacing={1}>
                {!selectedCompany ?
                    <Grid item xs={12}>
                        <Typography variant="h1">Please Select a Company</Typography>
                    </Grid>
                    :
                    presentationTemplates.length !== 0 ?
                    presentationTemplates.map((presentationTemplate) => {
                            return (
                                <Grid key={presentationTemplate._id} item xs={12} md={2.4}>
                                    <CardItem
                                        image={presentationTemplate.thumbnail}
                                        title={presentationTemplate.name}
                                        device={presentationTemplate.targetDevice}
                                        controls={true}
                                        onDelete={onDelete.bind(this,presentationTemplate)}
                                    />
                                </Grid>
                            )
                        })
                        :
                        !isLoading &&
                        <Grid item xs={12}>
                            <Typography variant="h1">This Company Has No Templates</Typography>
                        </Grid>
                }


            </Grid>
        </Content>
    )
}

export default Presentations