import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/auth-slice";
import uiSlice from "./reducers/ui-slice";
import companySlice from "./reducers/company-slice";
import userSlice from "./reducers/user-slice";
import teamSlice from "./reducers/team-slice";
import countrySlice from "./reducers/country-slice";
import templateSlice from "./reducers/template-slice";
import packagesSlice from "./reducers/packages-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    company: companySlice,
    users: userSlice,
    teams: teamSlice,
    country: countrySlice,
    templates: templateSlice,
    packages: packagesSlice,
  },
});

export default store;
