import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import Notification from "./components/ui/Notification";
import Loading from "./components/ui/Loading";
import "./styles/styles.scss";
import { userVerify } from "./store/actions/auth";
const App = () => {
  const { isLoggedIn, userData } = useSelector((state) => state.auth);
  const { notification, isLoading } = useSelector((state) => state.ui);
  const routing = useRoutes(routes(isLoggedIn));
  const dispatch = useDispatch();

  const handleFocus = () => {
    if (userData._id) {
      dispatch(userVerify({ id: userData._id }));
    }
  };
  const handleBlur = () => {
  };
  useEffect(() => {
    handleFocus()
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {notification && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      {routing}
    </>
  );
};
export default App;
