import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Typography } from "@mui/material";
const SelectBox = (props) => {
  return (
    <>
      {props.labelTag && (
        <Typography variant="body1" component="label" className="labelTag">
          {props.label}
        </Typography>
      )}
      <FormControl className={props.className} fullWidth>
        <InputLabel >{props.label}</InputLabel>
        <Select
          value={props.value}
          label={props.label}
          onChange={props.onChange}
        >
          {props.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default SelectBox;









