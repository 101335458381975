import React, { useEffect, useState } from "react";
import Content from "src/layouts/Content";
import PackagesForm from "./PackagesForm";
import { permissions } from "src/data/permissions";
import { Button } from "@mui/material";
import { createPackage } from "src/store/actions/packages";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPackage, updatePackage } from "src/store/actions/packages";

const PackagesCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const fetchPermissionData = useSelector(
    (state) => state.packages.permissionData
  );


  const [permissionsData, setPermissionsData] = useState(permissions);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(async () => {
    if (id) {
      setIsloading(true);
      dispatch(getPackage(id))
        .unwrap()
        .then(() => {
          setIsloading(false);
        })
        .catch((e) => {
          navigate("/packages");
        });
    }
  }, []);

  useEffect(() => {
    if (id) {
      const updatedPermissions = {
        ...permissions,
        name: fetchPermissionData.name,
        permissions: {
          ...permissions.permissions,
          ...fetchPermissionData.permissions
        }
      };
      setPermissionsData(updatedPermissions);
    }
  }, [fetchPermissionData]);


  const allCheckHandler = (mainKey, nestedKey, checked) => {
    const newData = JSON.parse(JSON.stringify(permissionsData.permissions));

    const updatePermissions = (data) => {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (typeof value === "object") {
          updatePermissions(value);
        } else if (typeof value === "boolean") {
          data[key] = checked
        } else if (!checked && !isNaN(value)) {
          data[key] = value;
        }
      });
    };

    if (mainKey && nestedKey) {
      updatePermissions(newData[mainKey][nestedKey]);
    } else if (mainKey && !nestedKey) {
      updatePermissions(newData[mainKey]);
    }

    setPermissionsData((prev) => ({ ...prev, permissions: newData }));
  };

  const permissionsOnChangeHandler = (mainKey, key, childKey, value) => {
    if (!key && !childKey && !mainKey) {
      setPermissionsData((prev) => ({
        ...prev,
        name: value
      }))
    }
    if (!childKey && !key && mainKey) {
      setPermissionsData((prev) => ({
        ...prev,
        permissions: {
          ...prev.permissions,
          [mainKey]: value
        }
      }))
    }
    if (!childKey && key) {
      setPermissionsData((prev) => ({
        ...prev,
        permissions: {
          ...prev.permissions,
          [mainKey]: {
            ...prev.permissions[mainKey],
            [key]: value
          }
        }
      }))
    }
    if (childKey) {
      setPermissionsData((prev) => ({
        ...prev,
        permissions: {
          ...prev.permissions,
          [mainKey]: {
            ...prev.permissions[mainKey],
            [key]: {
              ...prev.permissions[mainKey][key],
              [childKey]: value
            }
          }
        }
      }))
    }
  };

  const setWholeObjectValue = (data, path) => {
    if (typeof data == 'number') {
      return data
    }
    if (typeof data == 'boolean') {
      return data
    }
    if (typeof data == 'object') {
      return Object.values(data)
    }
  }

  const isAllChecked = (path) => {
    let arr = []
    Object.values(path).forEach((c) => {
      if (typeof setWholeObjectValue(c, path['maxNumberOfSlides']) !== 'object') {

        arr.push(setWholeObjectValue(c, path['maxNumberOfSlides']))
      } else {
        arr.push(setWholeObjectValue(c).includes(true))
      }
    })
    const isAllItemsChecked = arr.includes(true);
    return { isAllItemsChecked };
  };

  const onSaveHandler = async () => {
    setIsSaveLoading(true);
    let obj = {
      name: permissionsData.name,
      permissions: {}
    }
    Object.keys(permissionsData.permissions).forEach(e => {

      if (typeof permissionsData.permissions[e] == 'object') {
        obj.permissions[e] = permissionsData.permissions[e];
      } else {
        if (permissionsData.permissions[e])
          obj.permissions[e] = permissionsData.permissions[e]
      }
    })

    dispatch(id ? updatePackage({
      id,
      data: obj,
    }) : createPackage(obj))
      .unwrap()
      .then(() => {
        navigate("/packages");
        setIsSaveLoading(false);
      })
      .catch(() => {
        setIsSaveLoading(false);
      });
  };

  return (
    <Content title={`${id ? "Update" : 'Create'} Package`}>
      {!isLoading && <PackagesForm
        data={permissionsData}
        allCheckHandler={allCheckHandler}
        onChangeHandler={permissionsOnChangeHandler}
        isAllChecked={isAllChecked}
        actionButon={
          <Button
            disabled={isSaveLoading}
            variant="contained"
            onClick={onSaveHandler}
          >
            {`${id ? "Update" : 'Create'} Package`}
          </Button>
        }
      />}
    </Content>
  );
};

export default PackagesCreate;
