import React from "react";
import { useDispatch } from "react-redux";
import Logo from "../components/ui/Logo";
import LogoLarge from "../assets/images/logo.png";
import Copyright from "../components/ui/Copyright";
import { forgotPassword } from "src/store/actions/auth";
import ForgotPasswordForm from "src/components/login/ForgotPasswordForm";
import { Grid, Box, Typography } from "@mui/material";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const resetHandler = (e) => {
    e.preventDefault();
    let email = e.target.email.value;
    dispatch(forgotPassword(email));
  };
  return (
    <Grid className="login-page" spacing={2} container>
      <Grid item md={7}>
        <Box id="login-background"></Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography
            component="h1"
            variant="h1"
            className="login-heading"
            sx={{
              marginBottom: "30px !important",
            }}
          >
            Forgot Your Password?
          </Typography>
          <ForgotPasswordForm resetHandler={resetHandler} />
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};
export default ForgotPassword;
