import React, { memo, useState, useCallback } from "react";
import { Box, Button, TextField } from "@mui/material";
import Custom from "./Custom";
const CreateCategory = ({
  title,
  onCreate,
  type,
  selectedCompany,
  show,
  close,
}) => {
  const [categoryName, setCategoryName] = useState("");
  const [error, setError] = useState(false);

  const handleClose = () => {
    close();
    setCategoryName("");
    setError(false);
  };

  const createCategory = () => {
    if (!categoryName) {
      return setError(true);
    }
    if (onCreate) {
      onCreate({ data: { categoryName, type }, id: selectedCompany });
    }
    handleClose();
  };

  const handleChange = (e) => {
    if (error) {
      setError(false);
    }
    setCategoryName(e.target.value);
  };

  return (
    <Custom title={title} isOpen={show} handleClose={handleClose}>
      <TextField
        value={categoryName}
        onChange={handleChange}
        fullWidth
        placeholder="Enter Category Name"
        error={error}
        helperText={error ? "Plesae Enter Category Name" : ""}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{ marginTop: 4, textAlign: "center" }}
          onClick={createCategory}
          variant="contained"
        >
          Create
        </Button>
      </Box>
    </Custom>
  );
};

export default memo(CreateCategory);
