import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { IoAdd } from "react-icons/io5";

import { clearState } from "src/store/reducers/company-slice";
import { getCompanies } from "src/store/actions/company";
import {
  createTemplateCategory,
  fetchTamplatesCategories,
} from "src/store/actions/templates";

import SelectBox from "src/components/ui/formElements/SelectBox";
import Content from "src/layouts/Content";
import CreateCategory from "src/components/dialogs/CreateCategory";
import CardItem from "src/components/common/CardItem";

import defaultImage from "src/assets/images/template-thumb.png";

const TemplateCategory = ({ type }) => {
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { list } = useSelector((state) => state.company.fetching);
  const { list: categories, isEmpty } = useSelector(
    (state) => state.templates.categories
  );

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    // dispatch(fetchTamplatesCategories({ id: e.target.value, type }));
  };

  const onCreate = (data) => {
    dispatch(createTemplateCategory(data));
  };

  useEffect(() => {
    dispatch(clearState());
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (!type || !selectedCompany) return
    dispatch(fetchTamplatesCategories({ id: selectedCompany, type }));
  }, [selectedCompany, type]);

  return (
    <Content
      title={`${type?.charAt(0).toUpperCase()}${type?.slice(
        1
      )} Templates Categories`}
      action={
        <div className='custom-select-box'>
          <Grid spacing={1} container justifyContent="flex-end">
            <Grid item md={8}>
              <SelectBox
                className="company-select"
                label="Select Company"
                onChange={handleCompanyChange}
                options={
                  list.length !== 0
                    ? list.map((val) => {
                      return { label: val.name, value: val._id };
                    })
                    : [{ label: "", value: "" }]
                }
                value={selectedCompany}
              />
            </Grid>

            <Grid item md>
              <Button
                disabled={selectedCompany ? false : true}
                onClick={() => setIsOpen(true)}
                startIcon={<IoAdd />}
                variant="contained"
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    >
      <CreateCategory
        title="Create New Templates Category"
        selectedCompany={selectedCompany}
        onCreate={onCreate}
        type={type}
        show={isOpen}
        close={() => setIsOpen(false)}
      />
      <Grid container spacing={3}>
        {!selectedCompany ? (
          <Grid item xs={12}>
            <Typography variant="h1">Please Select a Company</Typography>
          </Grid>
        ) : isEmpty ? (
          <Grid item xs={12}>
            <Typography variant="h1">No Templates Categories Found</Typography>
          </Grid>
        ) : (
          categories.map((template) => {
            return (
              <Grid key={template._id} item xs={12} md={3}>
                <Link to={`${selectedCompany}/${template._id}`}>
                  <CardItem
                    image={defaultImage}
                    title={template.name}
                    titleClass="templateTitle"
                  />
                </Link>
              </Grid>
            );
          })
        )}
      </Grid>
    </Content>
  );
};

export default TemplateCategory;
