export const headCellsUser = [
  {
    id: "firstName",
    numeric: false,
    sort: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: true,
    sort: true,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    sort: true,
    label: "Email",
  },
  {
    id: "mobile",
    numeric: true,
    sort: false,
    label: "Mobile",
  },
  {
    id: "lang",
    numeric: true,
    sort: true,
    label: "Language",
  },
  {
    id: "status",
    numeric: false,
    sort: false,
    label: "Status",
  },
];

export const headCellsCompany = [
  {
    id: "name",
    numeric: false,
    sort: true,
    label: "Name",
  },
  {
    id: "primaryContact.email",
    numeric: false,
    sort: true,
    label: "Email",
  },
  {
    id: "phone",
    numeric: true,
    sort: false,
    label: "Phone",
  },
  {
    id: "status",
    numeric: false,
    sort: false,
    label: "Status",
  },
];

export const headCellsPackages = [
  {
    id: "name",
    numeric: false,
    sort: true,
    label: "Name",
  },
];

export const headCellsTeam = [
  {
    id: "name",
    numeric: false,
    sort: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    sort: true,
    label: "Email",
  },
  {
    id: "status",
    numeric: false,
    sort: false,
    label: "Status",
  },
  {
    id: "role",
    numeric: false,
    sort: false,
    label: "Role",
  },
];
