import React from "react";
import Header from "./header/Header";
import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
const MainLayout = (props) => {
  return (
    <>
      <Box className="bgImge2"></Box>
      <Grid container>
        <Grid item md={2} className="mainCol">
          <Sidebar type="main" />
        </Grid>
        <Grid item md={10} className="mainCol">
          <Header />
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default MainLayout;
