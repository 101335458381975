import { Box, Grid, Typography } from "@mui/material";
import "../styles/Content.scss";

const Content = (props) => {
  const { title, subtitle, children, action } = props;
  return (
    <Box className="content" sx={{ flexGrow: 1 }}>
      {title && (
        <Box className="contentHead">
          <Grid container spacing={2}>
            {/* left */}
            <Grid item xs={6} md={8}>
              <div className="left">
                <Typography variant="h5" component="h5" className="title">
                  {title}
                </Typography>
                <Typography variant="h4" component="h4" className="subtitle">
                  {subtitle}
                </Typography>
              </div>
            </Grid>
            {/* right */}
            <Grid item xs={6} md={4} px={0} >
              {action}
            </Grid>
          </Grid>
        </Box>
      )}

      <Box className="contentBody">{children}</Box>
    </Box>
  );
};

export default Content;
