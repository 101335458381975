import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

export const getTeamsList = createAsyncThunk(
  "getTeamsList",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getTeamsList(),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTeam = createAsyncThunk(
  "getTeam",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getTeam(id),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTeam = createAsyncThunk(
  "createTeam",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.createTeam(),
        data,
        thunkAPI,
        "Your Record Has Been Saved"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "updateAdmin",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updateAdmin(id),
        data,
        thunkAPI,
        "Your Record Has Been Updated"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
