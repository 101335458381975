import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

export const getPresentationTemplates = createAsyncThunk(
  "getPresentationTemplates",
  async ({ id, type,categoryId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getPresentationTemplates(id, type,categoryId),
        null,
        thunkAPI,
        "",
        true
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const uploadPresentationTemplate = createAsyncThunk(
  "uploadPresentationTemplate",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.uploadPresentationTemplate(id),
        data,
        thunkAPI,
        "Template has been uploaded",
        true
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSlideTemplates = createAsyncThunk(
  "getSlideTemplates",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getSlideTemplates(id),
        null,
        thunkAPI,
        "",
        true
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadSlideTemplate = createAsyncThunk(
  "uploadSlideTemplate",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.uploadSlideTemplate(id),
        data,
        thunkAPI,
        "Template has been uploaded",
        true
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchTamplatesCategories = createAsyncThunk(
  "fetchTamplatesCategories",
  async ({id , type}, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.fetchTemplatesCategories(id, type),
        undefined,
        thunkAPI,
        false,
        true
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTemplateCategory = createAsyncThunk(
  "createTemplateCategory",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.createTemplateCaterogy(id),
        data,
        thunkAPI,
        "Template Category has been added",
        true
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "updateTemplate",
  async ({ templateId, companyId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updateTemplate(templateId,companyId),
        data,
        thunkAPI,
        "Your Record Has Been Updated"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
