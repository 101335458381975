import { createSlice } from "@reduxjs/toolkit";
import { getCities, getCountries, getStates } from "../actions/countries";

const initialState = {
  fetching: { countries: [], states: [], cities: [] },
};
const countrySlice = createSlice({
  name: "Country slice",
  initialState,
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.fetching.states = [];
      state.fetching.cities = []

    },
    [getCountries.fulfilled]: (state, action) => {
      state.fetching.countries = action.payload;
    },
    [getStates.pending]: (state) => {
      state.fetching.cities = [];
    },
    [getStates.fulfilled]: (state, action) => {
      state.fetching.states = action.payload;
    },
    [getCities.fulfilled]: (state, action) => {
      state.fetching.cities = action.payload;
    },
  },
});

export default countrySlice.reducer;
