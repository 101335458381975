import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Switch,
  Link,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FaUsers } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { getFormattedCell, getComparator, stableSort } from "src/utils/Utils";
import { useDispatch } from "react-redux";
import { updateCompany } from "src/store/actions/company";
import { clearState } from "src/store/reducers/company-slice";
import { useNavigate } from "react-router-dom";



const CompanyList = ({ rows, headCells, initialRows = 10 }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else if (order === "desc") {
      setOrderBy("");
      setOrder("asc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChange = (event, row) => {
    event.preventDefault()
    dispatch(updateCompany({ id: row._id, data: { status: event?.target?.checked } }))
  };
  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          {/* Table Header Starts*/}
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index, array) => (
                <TableCell
                  sx={{
                    textOverflow: "ellipsis",
                    fontWeight: 900,
                    fontSize: "14px",
                  }}
                  key={headCell.id}
                  padding="normal"
                  align="left"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}

              <TableCell
                sx={{ textOverflow: "ellipsis" }}
                align="right"
                padding="normal"
              >
                Action
              </TableCell>

            </TableRow>
          </TableHead>
          {/* Table Header Endes*/}

          {/* Table Body Starts*/}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow padding="normal" hover tabIndex={-1} key={row._id}>
                    {headCells.map(({ id }, index, array) => {

                      return (
                        <TableCell
                          sx={{ fontWeight: 400 }}
                          key={index}
                          align="left"
                        >
                          {id === "status" ? (
                            (
                              <Switch
                                checked={row["status"]}
                                onChange={(res) => {
                                  handleChange(res, row)
                                }}
                              />
                            )
                          ) : (
                            getFormattedCell(row[id]) // row.status
                          )}
                        </TableCell>
                      );
                    })}

                    <TableCell sx={{ paddingRight: 0 }} align="right">

                      <Box>
                        <Tooltip title="View Company Users">
                          <Link
                            onClick={() => {
                              dispatch(clearState())
                              navigate(`/user/${row._id}`, { state: { companyName: row.name } })
                            }}
                            to={`/user/${row._id}`}
                            state={{ companyName: row.name }}
                          >
                            <IconButton>
                              <FaUsers size={18} />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <Link
                            onClick={() => {
                              dispatch(clearState())
                              navigate(`/company/${row._id}`)
                            }}
                            to={`/company/${row._id}`}>
                            <IconButton>
                              <RiPencilFill size={18} />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </Box>

                    </TableCell>

                  </TableRow>
                );
              })}
            {emptyRows > 0 || rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Record Found
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
          {/* Table Body Starts*/}
        </Table>
      </TableContainer>
      {/* Table Pagination*/}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: rows.length }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default CompanyList;
