import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { getTeamsList } from "../../store/actions/team";
import Content from "src/layouts/Content";
import Tables from "../../components/common/Tables";
import { headCellsTeam } from "../../components/common/tablesData";
import {
  clearState,
  deleteUser as deleteFromRedux,
} from "src/store/reducers/user-slice";
import { RiPencilFill } from "react-icons/ri";
import { AiFillDelete, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { updateAdmin } from "src/store/actions/auth";
import Custom from "src/components/dialogs/Custom";

const TeamActions = ({id}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const delteUserHandler = () => {
    dispatch(updateAdmin({data:{isDeleted:true}, id })).then(()=>dispatch(getTeamsList()));
  };


  const buttonStyle = {
    backgroundColor: '#d80000', // You can change this color to your preference
    color: 'white', // Text color
  };
  return (
    <>
      <Custom
        handleClose={handleClose}
        action={
          <div className="delete-button"  >
            <Button
              onClick={delteUserHandler}
              variant="contained"
              endIcon={<AiOutlineCheck />}
              style={buttonStyle}
            >
              Yes
            </Button>
            <Button
            sx={{ mr: 2 }}
              startIcon={<AiOutlineClose />}
              onClick={() => setIsOpen(false)}
              variant="outlined"
            >
              No
            </Button>
          </div>
        }
        isOpen={isOpen}
        title="Do you want to delete?"
      />
      <Box>
        <Tooltip title="Edit">
          <Link onClick={() => dispatch(clearState())} to={`/team/edit/${id}`}>
            <IconButton>
              <RiPencilFill size={18} />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Delete">
          <Link
            onClick={() => setIsOpen(true)}
          >
            <IconButton>
              <AiFillDelete size={18} />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    </>
  );
};


const TeamList = () => {

  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.teams.fetching);
  const { isLoading } = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(getTeamsList());
  }, []);

  return (
    <>
      {isLoading ? (
        <Typography variant="h2">Fetching Teams..</Typography>
      ) : (
        <Content
          title="Teams"
          action={
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link to="/team/create">
                  <Button variant="contained">Create Team</Button>
                </Link>
              </Grid>
            </Grid>
          }
        >
          <Tables Action={TeamActions} rows={list} headCells={headCellsTeam} />
        </Content>
      )}
    </>
  );
};

export default TeamList;
