import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RiPencilFill } from "react-icons/ri";
import {
  IconButton,
  Box,
  Typography,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import { getCompanies } from "../../store/actions/company";
import { headCellsCompany } from "../../components/common/tablesData";
import Content from "src/layouts/Content";
import { FaUsers } from "react-icons/fa";
import CompanyList from "./CompanyList"

const companyActions = ({ id, companyName }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Box>
        <Tooltip title="View Company Users">
          <Link
            onClick={() => dispatch(clearState())}
            to={`/user/${id}`}
            state={{ companyName }}
          >
            <IconButton>CompanyList
              <FaUsers size={18} />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Edit">
          <Link onClick={() => dispatch(clearState())} to={`/company/${id}`}>
            <IconButton>
              <RiPencilFill size={18} />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    </>
  );
};

const Companies = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.company.fetching);
  const { isLoading } = useSelector((state) => state.ui);
  useEffect(() => {
    dispatch(getCompanies());
  }, []);
  return (
    <>
      <Content
        title="Companies"
        action={
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/company/create">
                <Button variant="contained">Create Company</Button>
              </Link>
            </Grid>
          </Grid>
        }
      >
        <CompanyList
          Action={companyActions}
          rows={list}
          headCells={headCellsCompany}
        />
      </Content>
    </>
  );
};

export default Companies;
