import React,{useState} from "react";
import { Grid, Box, Typography, IconButton, Tooltip  } from "@mui/material";
import "./cardItem.scss";
import {IoTrash} from "react-icons/io5";
import ConfirmDialouge from "src/components/dialogs/Confirm";
const CardItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = () =>{
    setIsOpen(!isOpen)
  }
  return (
    <Box
      id={props.id}
      className={`cardItem ${props.classes}`}
      onClick={props.onClick}
    >
      <Box className="cardItemHeader">
        <Box className="image-wrap">
          <img src={props.image} />
        </Box>
      </Box>
      <Box className="cardItemBody">
        <Typography component="p" className="subtitle">
          {props.subtitle}
        </Typography>
        <Typography
          component="h2"
          className={`${props.titleClass ? props.titleClass : ""} title`}
        >
          {props.title}
        </Typography>
        {props.device && (
          <Typography
            component="p"
            className={`device`}
          >
            {props.device}
          </Typography>
        )}

        <Typography component="p" className="description">
          {props.description}
        </Typography>
      </Box>
      <ConfirmDialouge
            isOpen={isOpen}
            handleClose={toggleDialog}
            subtitle={"Are you sure you want to delete this template?"}
            handleConfirm={() => {
              props.onDelete()
            return true
            }}
        />
      {props.controls && (
        <Box className="cardItemFooter">
          <Box className="controls">
            <Grid
              direction="row"
              container
              justifyContent="flex-end"
              alignItems="center"
              className="Row"
            >
              
        <Tooltip title="Delete">
            <IconButton variant="outlined" onClick={toggleDialog}>
                <IoTrash />
            </IconButton>
        </Tooltip>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default CardItem;
