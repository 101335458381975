import { createSlice } from "@reduxjs/toolkit";
import { login, logout, userVerify } from "../actions/auth";
import cookie from "react-cookies";
import jsonwebtoken from "jsonwebtoken";

const user = cookie.load("admin");
let userData;
if (user) {
  const jwt = user?.jwt
  userData = jsonwebtoken.decode(jwt);
}

const initialState = user
  ? { isLoading: false, isLoggedIn: true, user, userData: userData }
  : { isLoading: false, isLoggedIn: false, user: null, userData: {} };

const authSlice = createSlice({
  name: "auth",
  initialState,

  extraReducers: {
    [login.pending]: (state) => {
      state.isLoading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.user = action.payload.user;
      const jwt = action.payload?.user?.jwt
      state.userData = jsonwebtoken.decode(jwt);
      // storing usre cookies
      cookie.save("admin", JSON.stringify(action.payload.user), { path: "/" });
      localStorage.setItem("admin", JSON.stringify(action.payload.user));
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      // removing user cookies
      cookie.remove("admin", { path: "/" });
      localStorage.removeItem("admin");
    },
    [userVerify.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.user = action.payload.user;
      const jwt = action.payload?.user?.jwt
      state.userData = jsonwebtoken.decode(jwt);
      // storing usre cookies
      cookie.save("admin", JSON.stringify(action.payload.user), { path: "/" });
      localStorage.setItem("admin", JSON.stringify(action.payload.user));
    },
    [userVerify.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      cookie.remove("admin", { path: "/" });
      localStorage.removeItem("admin");
    }
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    removeUser: (state, action)=>{
      state.isLoggedIn = false;
      state.user = null;
      cookie.remove("admin", { path: "/" });
      localStorage.removeItem("admin");
    },
    checkSFAuth: (state, action) => {
      const sfConfig = cookie.load("sf-config");
      if (sfConfig?.token && sfConfig?.expiry > Date.now()) {
        state.isSfIntegrated = true;
      } else {
        cookie.remove("sf-config", { path: "/" });
        state.isSfIntegrated = false;
      }
    },
  },
});

export default authSlice.reducer;
export const { setUserData, checkSFAuth,removeUser } = authSlice.actions;