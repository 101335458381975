import React from "react";
import { Box } from "@mui/material";
import "../../styles/sidebar.scss";
import SideBarItems from "./SideBarItems";
import logoImg from "../../assets/images/logo2.png";
import underline from "../../assets/images/logo-underline.png";
import { Link } from "react-router-dom";

const Sidebar = ({ classes }) => {
  return (
    <Box className={`sidebar ${classes}`}>
       <Link to="./dashboard/data" className="logo-container">
      <Box className="logo-container">
        <img src={logoImg} className="logo" />
        <img src={underline} className="logo-underline" />
      </Box>
      </Link>

      <ul className="menu">
        <SideBarItems />
      </ul>
    </Box>
  );
};

export default Sidebar;
