import { forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle, Grow } from "@mui/material";


const TransitionGrow = forwardRef(function Transition(props, ref) {
  return <Grow direction="left" ref={ref} {...props} />;
});
const style = {
  maxWidth:"500px",
  background:"white",
  boxShadow: " 0px 2px 5.5px rgba(0, 0, 0, 0.02), inset 0px -4px 24px rgba(188, 192, 221, 0.25)",
  backdropFilter: "blur(21px)",
  border: " 1.5px solid #FFFFFF",
  borderRadius: "15px",
  padding: "40px",
  display: "flex",
  alignItems: "center",
};
function Custom({ children, isOpen, handleClose,action,title }) {
  return (
    <Dialog
      fullWidth
      open={isOpen}
      TransitionComponent={TransitionGrow}
      onClose={handleClose}
      PaperProps={{
        style: style,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{action}</DialogActions>
    </Dialog>
  );
}
export default Custom;
