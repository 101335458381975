import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "src/store/actions/auth";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Tooltip,
  Button
} from "@mui/material";
import "./Header.scss";
import {useNavigate} from "react-router-dom";

import { IoPeopleCircle,IoLogOutOutline } from "react-icons/io5";
import personAvatart from "../../assets/images/person-avatar.jpeg";

import "../../styles/Header.scss";

const Header = () => {
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logoutHandler = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    dispatch(logout());
  };

  const handleProfileNavigate = () => {
    navigate("/profile/my-account");
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    // <Menu
    //   anchorEl={anchorEl}
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "right",
    //   }}
    //   id={menuId}
    //   keepMounted
    //   transformOrigin={{
    //     vertical: "top",
    //     horizontal: "right",
    //   }}
    //   open={isMenuOpen}
    //   onClose={handleMenuClose}
    // >
    //   <MenuItem onClick={logoutHandler}>Logout</MenuItem>
    // </Menu>

<Menu
anchorEl={anchorEl}
anchorOrigin={{
  vertical: "bottom",
  horizontal: "right",
}}
classes={{ list: "header-person-details-list" }}
id={menuId}
keepMounted
transformOrigin={{
  vertical: "top",
  horizontal: "right",
}}
open={isMenuOpen}
onClose={handleMenuClose}
PaperProps={{
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
}}
>
{/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
<Box className="header-person-details-container">
  <Box className="header-person-image">
    <img src={personAvatart} alt="" width="30" />
  </Box>
  <Box className="header-person-details">
    <h6>
      {userData.name}
    </h6>
    <p>{userData.email}</p>
    <Button variant="outlined" onClick={handleProfileNavigate}>
      Manage Your Account
    </Button>
  </Box>
</Box>
<MenuItem onClick={logoutHandler}>
  <IoLogOutOutline size="22" color="#2cc1d6" />
  Sign Out
</MenuItem>
</Menu>
  );

  return (
    <Box sx={{ flexGrow: 0 }} className="desktopHeader">
      <AppBar position="static">
        <Toolbar sx={{ padding: "0px !important", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            {/* <IconButton
              size="medium"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <IoPeopleCircle />
            </IconButton> */}

              <Tooltip title={userData.email}>
                <IconButton
                  size="medium"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  className="user-icon-header"
                >
                  <IoPeopleCircle />
                </IconButton>
              </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMenu}
    </Box>
  );
};
export default Header;
