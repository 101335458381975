import React from "react";
//Icons
import { MdSpaceDashboard } from "react-icons/md";
import { BiPhotoAlbum } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { CgOrganisation } from "react-icons/cg";
import { AiOutlineTeam } from "react-icons/ai";
import { MdOutlineCategory } from "react-icons/md";
import { GrMoney } from "react-icons/gr";

export const nav = [
  {
    label: "DashBoard",
    url: "dashboard/data",
    icon: <MdSpaceDashboard />,
  },
  {
    label: "Companies",
    url: "company",
    icon: <CgOrganisation />,
  },
  {
    label: "Packages",
    url: "packages",
    icon: <GrMoney />,
  },
  {
    label: "Templates",
    url: "templates/presentations",
    icon: <BiPhotoAlbum />,
  },
  {
    label: "Templates Category",
    url: "templates-category",
    icon: <MdOutlineCategory />,
  },
  {},
  {
    label: "Teams",
    url: "team",
    icon: <AiOutlineTeam />,
  },
];
