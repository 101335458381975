import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";

export const getPackages = createAsyncThunk(
  "getPackages",
  async (_, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getPackages(),
        null,
        thunkAPI
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPackage = createAsyncThunk(
  "getPackage",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getPackage(id),
        null,
        thunkAPI
      );

      if (response) {
        const responseData = response.data[0];
        return {
          name: responseData.name,
          permissions:responseData.permissions
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPackage = createAsyncThunk(
  "createPackage",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.createPackage(),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Created",
          message: "Package has been created !",
        })
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePackage = createAsyncThunk(
  "updatePackage",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updatePackage(id),
        data,
        thunkAPI
      );

      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Updated",
          message: "Package has been updated !",
        })
      );

      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
