import { Box, Grid, Button } from "@mui/material";
import Input from "src/components/ui/formElements/Input";

const ForgotPasswordForm = (props) => {
  return (
    <form onSubmit={props.resetHandler}>
      <Box className="loginForm">
        <Box className="ControlWrap">
          <Input
            label="Email or Username"
            type="email"
            name="email"
            placeholder="Email or Username"
            labelTag={true}
            className="border"
            required={true}
          />
        </Box>
      
        <Box mt={2} className="pt-4">
          <Button type="submit" variant="contained" fullWidth>
            Reset
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default ForgotPasswordForm;
