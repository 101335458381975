import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/actions/auth";

import { Box, Grid, Typography } from "@mui/material";
import LogoLarge from "../assets/images/logo.png";
import LoginForm from "src/components/login/LoginForm";
import Logo from "../components/ui/Logo";
import jsonwebtoken from "jsonwebtoken";
import Copyright from "../components/ui/Copyright";

import "../styles/login.scss";
import { setUserData } from "src/store/reducers/auth-slice";

const Login = () => {
  const { isLoggedIn, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  const loginUserHandler = ({ email, password }) => {
    // event.preventDefault();
    // const email = event.target.email.value;
    // const password = event.target.password.value;

    dispatch(login({ email, password }))
  };

  return (
    <Grid className="login-page" spacing={2} container>
      <Grid item md={7}>
        <Box id="login-background"></Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography component="h1" variant="h1" className="login-heading">
            Welcome to CreateSmart
          </Typography>
          <LoginForm loginHandler={loginUserHandler} isLoading={isLoading}/>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
