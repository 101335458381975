import React from "react";
import { useSelector } from "react-redux";
import { nav } from "src/_nav";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
const SideBarItems = () => {
  const active = "active";
  const id = useSelector((state) => state.users.company);
  return nav.map((navItem, index) => {
    if (navItem.url) {
      return (
        <li key={index}>
          <NavLink
            className={(navData) => (navData.isActive ? active : "")}
            to={navItem.url == "user" ? `user/${id}` : navItem.url}
          >
            <Typography variant="span" component="span" className="navIcon">
              {navItem.icon}
            </Typography>
            <Typography variant="body2" component="span" className="navText">
              {navItem.label}
            </Typography>
          </NavLink>
        </li>
      );
    } else {
      return <div key={`divider-${index}`} className="divider"></div>
    }
  });
};
export default SideBarItems;
