import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTamplatesCategories,
  getPresentationTemplates,
  uploadPresentationTemplate,
} from "src/store/actions/templates";
import CardItem from "src/components/common/CardItem";
import Content from "src/layouts/Content";
import { Button, Grid, Typography } from "@mui/material";
import { IoAdd } from "react-icons/io5";
import { getCompanies } from "src/store/actions/company";
import UploadFrom from "src/components/dialogs/UploadFrom";
import SelectBox from "src/components/ui/formElements/SelectBox";
import { clearState } from "src/store/reducers/template-slice";

const Emails = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.ui);
  const { list } = useSelector((state) => state.company.fetching);
  const { presentations } = useSelector((state) => state.templates.templates);
  const { list: categories, isEmpty } = useSelector(
    (state) => state.templates.categories
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [category, setCategory] = useState("");
  useEffect(() => {
    dispatch(clearState());
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        fetchTamplatesCategories({ id: selectedCompany, type: "email" })
      );
    }
  }, [selectedCompany]);

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    dispatch(getPresentationTemplates({ id: e.target.value, type: "email" }));
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <Content
      title="Emails"
      action={
        <div className='custom-select-box'>
        <Grid spacing={1} container justifyContent="flex-end">
          <Grid item md={8}>
            <SelectBox
              className="company-select"
              label="Select Company"
              onChange={handleCompanyChange}
              options={
                list.length !== 0
                  ? list.map((val) => {
                      return { label: val.name, value: val._id };
                    })
                  : [{ label: "", value: "" }]
              }
              value={selectedCompany}
            />
          </Grid>
          <Grid item md>
            <Button
              disabled={selectedCompany ? false : true}
              onClick={() => setIsOpen(true)}
              startIcon={<IoAdd />}
              variant="contained"
            >
              Add New
            </Button>
          </Grid>
        </Grid>
        </div>
      }
    >
      <UploadFrom
        selectedCompany={selectedCompany}
        dispatchFunction={uploadPresentationTemplate}
        type="email"
        show={isOpen}
        title="Upload From"
        close={() => setIsOpen(false)}
        externalFields={{ categoryId: category }}
        externalElement={
          <SelectBox
            fullWidth
            label="Select Category"
            onChange={handleCategoryChange}
            options={
              categories.length !== 0
                ? categories.map((val) => {
                    return { label: val.name, value: val._id };
                  })
                : [{ label: "", value: "" }]
            }
            value={isEmpty ? "No Categories" : category}
          />
        }
      />
      <Grid container spacing={3}>
        {!selectedCompany ? (
          <Grid item xs={12}>
            <Typography variant="h1">Please Select a Company</Typography>
          </Grid>
        ) : presentations.length !== 0 ? (
          presentations.map((presentation) => {
            return (
              <Grid key={presentation._id} item xs={12} md={3}>
                <CardItem
                  image={presentation.thumbnail}
                  title={presentation.name}
                />
              </Grid>
            );
          })
        ) : (
          !isLoading && (
            <Grid item xs={12}>
              <Typography variant="h1">
                This Company Has No Templates
              </Typography>
            </Grid>
          )
        )}
      </Grid>
    </Content>
  );
};

export default Emails;
